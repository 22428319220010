<template>
    <v-dialog v-model="dialog" persistent max-width="500px">
        <v-form @submit="ok" @submit.prevent>
            <v-card>
                <v-card-title>
                    <span class="headline">
                        <slot name="title" />
                    </span>
                </v-card-title>
                <v-card-text>
                    <slot />
                </v-card-text>

                <v-card-actions>
                    <div class="flex-grow-1" />
                    <v-btn type="submit" color="quaternary" text>
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>

export default {
    data() {
        return {
            dialog: true
        }
    },
    methods: {
        ok() {
            this.$emit('input')
        }
    },
};
</script>
